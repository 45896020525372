<template>
  <div class="sliderdiv">
   
    <div class="one">
      <van-slider :bar-height="3" active-color="#477BFD" v-model="self_pledgeCom" inactive-color="#cfdbfa">
        <template #button>
        
          <div class=""></div>
        </template>
      </van-slider>
    </div>
    <div class="two">
      <van-slider :bar-height="3" active-color="#C1504A" v-model="self_pledgeCom2" inactive-color="rgba(243,220,219, 1)">
        <template #button>
          
          <div class=""></div>
        </template>
      </van-slider>
    </div>
    <div class="three">
      <van-slider :bar-height="3" active-color="#9BBB5D" v-model="self_pledgeCom3" inactive-color="rgba(234,241,223, 1)">
        <template #button>
         
          <div class="" ></div>
        </template>
      </van-slider>
    </div>
    
    <div class="sipper-button" v-if="self_pledge <= 300">
            <div class="sipper-button-abs">{{self_pledge}}/300</div>
          </div>
    <div class="sipper-button" v-else-if="self_pledge > 300 && self_pledge <= 1000">
            <div class="sipper-button-abs">{{self_pledge}}/1000</div>
          </div>
    <div class="sipper-button" v-else-if="self_pledge > 1000 && self_pledge <= 3000">
            <div class="sipper-button-abs">{{self_pledge}}/3000</div>
          </div>
  </div>
</template>

<script>
export default {
  props: {
    self_pledge: {
      type: Number,
      default: 0,
    },
  },
  name: 'sliderdiv',
  data() {
    return {};
  },
  computed: {
    self_pledgeCom() {
      if (this.self_pledge <= 300) {
        return (this.self_pledge/300)*100;
      } else {
        return 100;
      }
    },
    self_pledgeCom2() {
      if (this.self_pledge <= 300) {
        return 0;
      } else if (this.self_pledge > 300 && this.self_pledge <= 1000) {
        return (this.self_pledge/1000)*100;
      }  else {
        return 100;
      }
    },
    self_pledgeCom3() {
      if (this.self_pledge <= 300) {
        return 0;
      } else if (this.self_pledge > 300 && this.self_pledge <= 1000) {
        return 0;
      } else if (this.self_pledge > 1000 && this.self_pledge <= 3000) {
        return (this.self_pledge/3000)*100;
      } else {
        return 100;
      }
    },
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped lang='less'>
.sliderdiv {
  position: relative;
  width: 100%;
  display: flex;
  .one {
    width: 15%;
  }
  .two {
    width: 35%;
  }
  .three {
    width: 50%;
  }
  & > div {
    height: 3px;
    border-radius: 12px;
  }
}
.sipper-button {
  // width: 8px;
  // height: 8px;
  // border-radius: 50%;
  // border: 2px solid #ae47fd;
  // background: #fff;
  position: absolute;
// right: 20px;
// top: 20px;
right: 0;
top: 10px;
}
.sipper-button-abs {
  
  font-size: 12px;
  color: #477bfd;
}
</style>
