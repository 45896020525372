export const lang = {
  swap1: '闪兑',
  swap2: '立刻开始闪兑',
  swap3: 'from',
  swap4: 'balance: ',
  swap5: '选择币种',
  swap6: 'max',
  swap7: '价格',
  swap8: '滑点容差',
  swap9: '连接钱包',
  swap10: '输入数量',
  swap11: '余额不足',
  swap12: '此交易的流动性不足',
  swap13: '选择代币',
  swap14: '授权',
  swap15: '包装',
  swap16: '解包',
  swap17: '闪兑',
  swap18: '交易收据',
  swap19: '正在等待确认',
  swap20: '正在将',
  swap21: '交换为',
  swap22: '在你的钱包种确认此交易',
  swap23: '已提交交易',
  swap24: '在BSC上查看',
  swap25: '关闭',
  swap26: '交易拒绝',
  swap27: '确认交换',
  swap28: '输出为估值,您将获得至少{inputOutMin} {symbol},或者交易将被撤回',
  swap29: '输入为估值,您将出售最多{inputInMax} {symbol},或者交易将被撤回',
  swap30: '最小获得量',
  swap31: '最大出售量',
  swap32: 'to',
  swap33: '管理代币',
  swap34: '导入代币',
  swap35: '导入',
  swap36: '管理Tokens',
  swap37: '自定义代币',
  swap38: '全部清除',
  swap39: '搜索名称或粘贴地址',
  swap40: '设置',
  swap41: '默认交易速度(GWEI)',
  swap42: '标准',
  swap43: '快速',
  swap44: '即时',
  swap46: '输入有效的滑点百分比',
  swap47: '你的交易可能会失败',
  swap48: '你的交易可能会被超前',
  swap49: '交易截止时间(分钟)',
  swap50: '估计',
  swap51: '禁用多跳',
  swap52: '最近交易',
  swap53: '最近没有交易',
  swap54: '全部清除',
  swap55: '链接钱包',
  swap56: '加入董事会',
  swap57: '捐赠',
  swap58: '权益赠送',
  swap59: '董事会成员列表',
  swap60: '邀请',
  swap61: '复制',
  swap62: '我的DAPP邀请人:',
  swap63: '邀请记录',
  swap64: '出错啦,请稍后再试',
  swap65: '功能暂未开放',
  swap66: '领取',
  swap67: '取出成功',
  swap68: '捐赠中,待确认',
  swap69: '已捐赠',
  swap70: '非白名单用户',
  swap71: '捐赠无效',
  swap72: '当前余额不足',
  swap73: '授权中,请稍等',
  swap74: '授权失败',
  swap75: '捐赠中,请稍等',
  swap76: '捐赠失败',
  swap77: '兑换',
  swap78: '流动性',
  swap79: '会员',
  swap80: '邀请',
  swap81: '权益池',
  swap82: '跨链桥',
  swap83: '池子',
  swap84: '农场',
  swap85: '官网',
  swap86: '市场',
  swap87: '兑换',
  swap88: '交易',
  swap89: '赚取',
  swap90: '确认',
  swap91: '价格影响',
  swap92: '最小获得量',
  swap93: '流动性供应商费用',
  swap94: '增加流动性',
  swap95: '增加流动性以接收 LP 代币',
  swap96: '流动性池中的份额',
  swap97: '兑换率和流动池份额',
  swap98: '您的流动性',
  swap99: '移除流动性以收回代币',
  swap100: '未找到流动性',
  swap101: '未看到您加入的流动池？',
  swap102: '查看其他LP代币',
  swap103: '已入池',
  swap104: '移除',
  swap105: '供应',
  swap106: '导入池',
  swap107: '导入现有流动性池',
  swap108: '选择代币以查找您的流动性。',
  swap109: '您在该流动性池中还没有提供流动性。',
  swap110: '您的钱包中的LP代币',
  swap111: '接收',
  swap112: '和',
  swap113: '金额',
  swap114: '你将获得',
  swap115: '输出为估值。如果价格变化超过 {slippage}%,则您的交易将被撤回',
  swap116: '已焚毁',
  swap117: '已入金',
  swap118: '确认供应',
  swap119: '汇率',
  swap120: '您是第一个流动性供应商。',
  swap121: '您添加代币的比列,将决定该流动性池的初始价格。',
  swap122: '如果您对汇率满意,请点击“供应”以进行检查。',
  swap123: '正在移除',
  swap124: '正在供应',
  swap125: '确认供应',
  swap126: '白皮书',
  swap127: '审计',
  swap128: '公告',
  swap129: '提示',
  swap130: 'PHO 1:1 兑换',
  swap131: '更多',
  swap132: '正在加载',
  swap133: '信息',
  swap134: '捐赠名额已满',
  swap135: '我的PHO',
  swap136: '钱包中的PHO',
  swap137: '可收割的PHO',
  swap138: 'PHO价格',
  swap139: 'PHO总量',
  swap140: 'PHO市值',
  swap141: 'PHO信息',
  swap142: '已赚取',
  swap143: '质押',
  swap144: '个人TVL',
  swap145: '社区TVL',
  swap146: '收割',
  swap147: '累计激励 ',
  swap148: '今日激励',
  swap149: '参与市商地址数',
  swap150: '我的邀请链接',
  swap151: '最大算力区',
  swap152: '其他算力区',
  swap153: '我的好友邀请',
  swap154: '取消',
  swap155: '修改邀请人',
  swap156: '邀请人不能为空',
  swap157: '修改成功',
  swap158: '质押LP代币',
  swap159: '赎回LP代币',
  swap160: '赎回',
  swap161: '获取',
  swap162: '当前暂无收益',
  swap163: '路由',
  swap164: '审计机构',
  swap165: '合作伙伴',
  swap166: '发行',
  swap167: '活动',
  swap168: '单币池',
  swap169: 'LP池',
  swap170: '国会',
  swap171: '卡牌',
  swap172: '盲盒',
  swap173: '游戏',
  swap174: '权益池已分配总额',
  swap175: '待领取数额',
  swap176: '我已获取数额',
  swap177: '取出',
  swap178: '超级节点',
  swap179: '权益卡已领取份额',
  swap180: '国库',
  swap181: '国库累计金额',
  swap182: '国库已分配金额',
  swap183: '国库已回购金额',
  swap184: '专家模式',
  swap185: 'D6 Swap是基于OKExChain研发的全网首个股权税收制Defi平台,逐步推出Swap、NFT、Gamefi、Lend、机枪池等板块。面世以来通过领先的技术和公平公开的市场理念,提供更加广泛的资产增值方案和前所未有的体验感,深受广大用户喜爱。',
  swap186: '一键取出',
  swap187: '价格影响过高',
  swap188: 'FAQ',
  swap189: 'NFT',
  swap190: '赚币',
  swap191: '进行中',
  swap192: '已结束',

  swap193: '说明',
  swap194: '股权权益生态卡,全球限量1680张',
  swap195: '点亮股权权益生态卡的要求及收益如下',
  swap196: '一星卡：个人地址拥有价值1000USDT流动性,团队1000USDT流动性,收益共分交易税收0.1%',
  swap197: '二星卡：个人拥有价值2000USDT流动性,团队1万USDT流动性,收益共分交易税收0.1%',
  swap198: '三星卡：个人3000USDT流动性,团队10万USDT流动性,设立工作室,收益共分交易税收0.1%',
  swap199: '四星卡：个人拥有价值4000USDT流动性,团队100万USDT流动性,设立工作室,收益共分交易税收0.1%',
  swap200: '五星卡：个人5000USDT流动性,团队1000万USDT流动性,设立工作室,收益共分交易税收0.1%',
  swap201: '备注：所有权益生态卡收益全部链上自动分配,权益卡享受交易所所有生态分红,权益卡可传承可交易可转让,满足要求自动升级,不满足要求自动降级及自动退出。',
  swap202: '权益卡',
  swap203: '发展路线图：',
  swap204: '2022年7月  2.0版上线',
  swap205: '2022年9月  开通持币多挖',
  swap206: '2022年12月 NFT交易平台上线',
  swap207: '2023年 6月  Goodbaby游戏上线',
  swap208: '2023年 9月  Goodlend上线',
  swap209: 'D6 Swap介绍：',
  swap210: '滑点：',
  swap211: '分配：',
  swap212: '买入',
  swap213: '卖出',
  swap214: '0.5% LP分红',
  swap215: '0.5% 生态权益卡分红',
  swap216: '0.25% 回流底池销毁LP',
  swap217: '0.25% 生态建设',
  swap218: '暂无奖励领取',
  swap219: '个人TVL',
  swap220: '团队TVL',
  swap221: 'LP数量',
  swap222: '钱包中的pho',
  swap223: 'GDT',
  swap224: 'GDT空投累计奖励',
  swap225: '星级用户累计奖励',
  swap226: '免手续费倒计时',
  swap227: '可取出奖励',
  swap228: 'LP挖矿奖励',
  swap229: '质押pho',
  swap230: '赎回pho',
  swap231: '目标共识',
  swap232: '已达共识',
  swap233: '已共识',
  swap234: '共识',
  swap235: '已解锁',
  swap236: '可领取',
  swap237: '社区治理',
  swap238: '联盟共识',
  swap239: '社区联盟',
  swap240: '质押代币',
  swap241: '质押中,请稍等',
  swap242: '交易挖矿',
  swap243: '昨日新增',
  swap244: '社区白名单累计奖励',
  swap245: '股权星卡累计奖励',
  swap246: 'LP挖矿累计奖励',
  swap247: '当前可领取',
  swap248: '当前可领取金额',
  swap249: '交易挖矿',
  swap250: '我的分享奖励累计',
  swap251: '我的交易奖励累计',
  swap252: '请输入您的交易哈希',
  swap253: '校验',
  swap254: '请输入您的哈希',
  swap255: '您的交易地址',
  swap256: '您将获得',
  swap257: '仅买入GDT及创建流动性的交易可获得奖励',
  swap258: '质押GDT-PHO代币',
  swap259: '赎回GDT-PHO代币',
  swap260: '全网手续费累计',
  swap261: '待领取分红',
  swap262: 'GDT联合股东',
  swap263: '社交',
  swap264: '商城',
  swap265: '精选好物',
  swap266: '优选小店',
  swap267: '即将上线',
  swap268: 'VGT联创股东',
  swap269: '质押VGT-PHO代币',
  swap270: '赎回VGT-PHO代币',
  swap271: '推荐链接',
  swap272: '请绑定领导人',

  swap273: '详细',
  swap274: '元宇宙',
  swap275: '全局',
  swap276: '交换和流动性',
  swap277: '交易、发展、赚钱、',
  swap278: '多链聚合DEX生态平台',
  swap279: '享受最低的交易费,有利可图的功能和多类型推荐计划',
  swap280: '路线图',
  swap281: '2022年7月 2.0版上线',
  swap282: '2022年9月 开通持币多挖',
  swap283: '2022年12月 NFT交易平台上线',
  swap284: '2023年6月 Goodbaby游戏上线',
  swap285: '2023年9月 Goodlend上线',
  swap286: '了解更多',
  swap287: '您已持有',
  swap288: '在',
  swap289: '交易加密数字货币',
  swap290: '质押流动性和代币',
  swap291: '赚取分红奖励',
  swap292: '发射台',
  swap293: '认证',
  swap294: '优质加密数字资产',
  swap295: '了解',
  swap296: '艺术品',
  swap297: '交易市场',
  swap298: '浏览',
  swap299: '即时兑换代币',
  swap300: '复制成功',
  swap301: '不支持复制,该浏览器不支持自动复制',
  swap302: '联创累计奖励',
  swap303: '累计分享奖励',
  swap304: '累计交易奖励',
  swap305: '资产包',
  swap306: '交易赚币',
  swap307: '可取出余额',
  swap308: '请输入交易哈希',
  swap309: '确定',
  swap310:'请输入hash值',
  swap311:'入账成功',

  swap312:'币安',
  swap313:'平台',
  swap314:'PHO专区',
  swap315:'GDT专区',
  swap316:'VGT专区',
swap317:'PHO星级卡',
swap318:'个人',
swap319:'团队',
swap320:'钱包中的',
swap321:'选择资产包',
swap322:'请选择',
swap323:'日收益率',
swap324:'收益比例',
swap325:'到期总数',
swap326:'购买',
swap327:'您的钱包余额',
swap328:'我的资产包',
swap329:'今日总收益',
swap330:'今日静态收益',
swap331:'今日动态收益',
swap332:'累计收益',
swap333:'到期数量',
swap334:'选择',
swap335:'加密货币专区',
swap336:'购买成功',
swap337:'进入 DApp',
swap338:'圈子',
swap339:'与你的加密圈伙伴一起畅聊',
swap340:'糖果盒',
swap341:'多种优质资产空投',
swap342:'快来免费领取',
swap343:'众创池',
swap344:'铸币中心',
swap345:'社区圈子',
swap346:'糖果盒子',
swap347:'赚币',
swap348:'空投',
swap349:'分享中心',
swap350:'联盟计划',
swap351:"社交",
swap352:"桥",
swap353:"映射",
swap354:"Water 矿池",
swap355:"参与矿池建设,自由赚取高额激励",
swap356:"流动性矿池",
swap357:"单币质押池",
swap358:"实时",
swap359:"已完成",
swap360:"仅限已质押",
swap361:"排序依据",
swap362:"搜索",
swap363:" 热门 ",
swap364:"搜索矿池",
swap365:"这里什么也没有！",
swap366:"首个Water Swap的启动模板",
swap367:"为每个优质投资者和加密资产找到最佳匹配路径 ",
swap368:"创建ISO",
swap369:"成为未来的加密明星",
swap370:"即将到来",
swap371:"请先绑定推荐人",
swap372:"我的邀请人",
swap373:"邀请人地址：",
swap374:"",
swap375:"Q3  推出并上线D6 Swap,并开启创世铸币,同时引入加密社交平台CIRCLE,以深度链接社区生态发展。",
swap376:"Q4  对D6进行流动性注入,开启 “D6共识计划”,打造D6流通共识,并启用流动性激励计划。推出链上联盟跨境商城,打造“消费增值”生态,推动社区与商业结合新方向。",
swap377:"",
swap378:"Q1 一揽子联盟扶持计划,推动D6作为基础流动性支持,提高市场认可和占比度,上线D-Pay,促进D6 Coin的实体交易流通",
swap379:"Q2 正式上线D6 Trade,开启CEX和DEX双交易平台时代,进一步提高D6市场交易流动性,并引入新的伙伴。",
swap380:"",
swap381:"",
d1:"充币",
d2:"提币",
d3:"转账",
d4:"账单",
d5:"社区",
d6:"闪兑",
d7:"问题反馈",
d8:"铸造",
d9:"赚取D6 Coin",
d10:"收益高达",
d11:"进入",
d12:"Water行情",
d13:"今日价格",
d14:"今日收益",
d15:"查看",
d16:"资产",
d17:"隐藏零资产",
d18:"币种",
d19:"冻结",
d20:"可用",
d21:"选择通证",
d22:"充值数量",
d23:"请输入充值数量",
d24:"钱包余额:",
d25:"入账地址",
d26:"确定",
d27:"充值记录",
d28:"提取数量",
d29:"请输入提取数量",
d30:"账户余额：",
d31:"到账地址",
d32:"提币记录",
d33:"转账数量",
d34:"请输入转账数量",
d35:"输入对方地址",
d36:"请输入对方地址",
d37:"转账记录",
d38:"资产兑换",
d39:"支付",
d40:"得到",
d41:"数量",
d42:"全部",
d43:"最多可兑换：",
d44:"交易税：",
d45:"应到账：",
d46:"支付数量：",
d47:"闪兑记录",
d48:"确认兑换",
d49:"闪兑记录",
d50:"已收益",
d51:"待收益",
d52:"燃烧金额",
d53:"请输入燃烧金额",
d54:"进行中",
d55:"已结束",
d56:"流通进度",
d57:"暂无记录！",
d58:"历史收益",
d59:"我的等级",
d60:"当前等级",
d61:"注册后即可成为 D0用户",
d62:"好友邀请计划",
d63:"改为引荐好友共同参与,开启KJU-DAO共识革命,加速赚取奖励！",
d64:"复制",
d65:"我的推荐人",
d66:"我的有效推荐",
d67:"地址",
d68:"级别",
d69:"业绩",
d70:"下一等级：",
d71:"市场业绩",
d72:"尚未完成",
d73:"市场部门",
d74:"您已是最高级别",
d75:"公告",
d76:"链接确认",
d77:"领导人地址：",
d78:"确认链接",
d79:"不能为负和零",
d80:"余额不足",
d81:"充币成功",
d82:"充币失败",
d83:"手续费：",
d84:"转入",
d85:"转出",
d86:"账单记录",
d87:"时间",
d88:"类型",
d89:"数量",
d90:"币种",
d91:"请先选择支付通证",
d92:"此通证禁止双向兑换",
d93:"不能选择相同的通证",
d94:"请先绑定领导人",
d95:"剩余可燃烧金额",
d96:"请先绑定推荐人",
d97:"请填写推荐人邀请码",
d98:"最新公告",
d99:"注：",
d100:"2:提币订单,预计为T+1日确认",
d101:"1:单笔提币 {min_withdraw} {token_name} 起",
d102:"3:每笔提币收取2 USDT税收",
d103:"团队地址",
d104:"团队有效",
d105:"今日充值",
d106:"今日提取",
d107:"个人",
d108:"团队",
d109:"开启后,达到铸造条件,自动参与铸造",
d110:"充值后预计1-2分钟到账",
d111:"每笔转账收取{feerate}%交易税",
d112:"如有疑问,请遗留问题反馈！",
d113:"反馈问题",
d114:"反馈列表",
d115:"反馈内容",
d116:"线上客服",
d117:"暂未回复",
d118:"回复：",
d119:"1.意见反馈",
d120:"2.添加图片(最多3张)",
d121:"提交",
d122:"请上传png或jpg格式图片",
d123:"上传中,请稍候...",
d124:"文件大小不能超过 10M",
d125:"用户",
d126:"CIRCLES  AirDrop 矿池",
d127:"发行总量",
d128:"当前产出",
d129:"提取",
d130:"我的余额",
d131:"分享",
d132:"链接数量",
d133:"工作的矿机",
d134:"时",
d135:"分",
d136:"秒",
d137:"挖矿中",
d138:"启动挖矿",
d139:"邀请好友",
d140:"好友启动挖矿",
d141:"获得分享收益",
d142:"一级分享",
d143:"地址",
d144:"状态",
d145:"没有更多了",
d146:"二级分享",
d147:"质押网络",
d148:"进入",
d149:"CIRCLES致力于加密社交隐私公链建设,计划将于2025年推出,公链代币总量20亿枚,每个用户可领取1T算力,逐量衰减,累计免费挖出80%即止。",
d150:"总资产估值",
d151:"9·18 通证",
d152:"9·18通证是WaterSwap合作的首个以历史题材社区通证,以“人人持有、永世不忘”为目标,铭记历史,杜绝淡化,从9·18通证开始 ！即刻免费领取吧！",
d153:"挖矿规则",
d154:"每个用户可获得1T算力,每T算力初始24小时产出30枚,每产出1000万枚算力递减20%。",
d155:"直接邀请50%,间接邀请25%算力加成激励！",
d156:"领取空投",
d157:"提币尚未开放",
d158:"当您绑定市场领袖后,即可领取9.18枚918通证空投。",
d159:"若您参与分享,每分享10个用户领取空投,您将再获得一次空投奖励资格,最高可获得918枚！",
d160:"我的空投",
d161:"普惠空投",
d162:"我的分享",
d163:"提币尚未开放",
d164:"当前等级",
d165:"查看收益",
d166:"普通用户",
d167:"VIP会员",
d168:"升级",
d169:"复制邀请码",
d170:"地址",
d171:"状态",
d172:"生态",
d173:"持有卡牌",
d174:"参与生态建设,获得丰厚奖励",
d175:"今日可领取",
d176:"C2C交易市场",
d177:"当前等级",
d178:"领取",
d179:"进入",
d180:"您没有邀请码",
d181:"我的市场",
d182:"不能购买",
d183:"您的领袖未成为会员",
d184:"链上余额不足",
d185:"平台余额不足",
d186:"签名失败",
d187:"转账失败",
d188:"续费",
d189:"邀请码",
d190:"你需支付",
d191:"钱包余额",
d192:"余额",
d193:"平台余额",
d194:"支付中...请等待",
d195:"支付成功",
d196:"确定支付",
d197:"关闭",
d198:"支付失败",
d199:"交易",
d200:"赚币",
d201:"空投",
d202:"社区",
d203:"生态中心",
d204:'选择网络',
d205:"枚",
d206:"已领取",
d207:"未领取",

d208:"全网质押量",
d209:"昨日排行榜",
d210:"地址",
d211:"新增右域算力",
d212:"个人中心",
d213:"节点奖励",
d214:"收益",
d215:"推荐链接",
d216:"普通会员",
d217:"轻节点",
d218:"全节点",
d219:"超级节点",
d220:"首页",
d221:"质押",
d222:"公告",
d223:"排行榜",
d224:"社区",
d225:"生态中心",
d226:"矿池",
d227:"累计发放总量",
d228:"昨日发放总量",
d229:"超级网络",
d230:"余额",
d231:"可赎回",
d232:"赎回",
d233:"请输入赎回数量",
d234:"取消",
d235:"100起赎回",
d236:"起质押",
d237:"确认",
d238:"链接地址",
d239:"恭喜您晋升当前等级",
d240:"总算力",
d241:"小区算力",
d242:"个人算力",
d243:"算力系数",
d244:"可提取余额",
d245:"提取收益",
d246:"不可质押",
d247:"请输入质押数量",
d248:"最少质押",
d249:"质押失败",
d250:"星级级别",
d251:"KJU-DAO是由KAD联盟社区和聚币DAO发起，为流量入口打造的一个新型架构，主要通过链接更多的用户为基础，增加更强的流动性而创建。这个系统更加智能，更加完善的把分享奖励做了多次分配，让积极分享，并且拥有一定粉丝的团体能够更直观的获取应属于自己的奖励！",
d252:"我的质押",
d253:"正在处理中 请不要关闭或者刷新页面",
d254:"链接失败",
d255:"链接成功",
d256:"链接地址不能是当前地址",
d257:"请输入正确的地址",
d258:"链接中,请等待",
d259:"请输入链接地址",




};
