<template>
  <div style="background: #4c7ef9">
    <div style="display: flex; align-items: center; position: relative; justify-content: center; width: 100%; margin-bottom: 20px">
      <topbutton router="snowguan" style="position: absolute; left: 20px; top: 30px"></topbutton>
      <div style="margin-top: 30px; font-size: 18px; font-weight: 500; color: #fff">{{$t('lang.d250')}}</div>
      <div></div>
    </div>
    <div class="shequ">
      <div class="my-swipe">
        <div class="my-swipe-item" :style="`background: url(${testlist[levelInfo.team_level].bgcard}) no-repeat;`">
          <div class="item-title">{{$t('lang.d213')}}</div>
          <div class="item-rate">{{ levelInfo.day_production }}</div>
          <div class="item-text">{{$t('lang.d239')}}</div>
          <div class="item-level" :style="`color: ${testlist[levelInfo.team_level].color} ;`">{{ $t(testlist[levelInfo.team_level].level )  }}</div>
          <div class="sipper2">
            <div class="sipper-line">
              <span class="line-left">{{$t('lang.d240')}}</span>
              <span class="line-right">{{$t('lang.d241')}}</span>
            </div>
            <van-slider :bar-height="3" active-color="#AE47FD" v-model="levelInfoComRate"  inactive-color="#F1A661">
              <template #button>
                <div class="sipper-button">
                  <!-- <div class="sipper-button-abs">{{ levelInfo.team_pledge }}/{{ levelInfo.mz_pledge }}</div> -->
                </div>
              </template>
            </van-slider>
            <div style="width: 100%; text-align: right; margin-bottom: 7px; font-size: 12px; margin-top: 5px; color: #477bfd;">{{ levelInfo.team_pledge }}/{{ levelInfo.mz_pledge }}</div>
          </div>
          <div class="sipper2" style="margin-top: 4px">
            <div class="sipper-line">
              <span class="line-left">{{$t('lang.d242')}}</span>
              <span class="line-right" style="color: #477bfd">{{$t('lang.d243')}}:{{levelInfo.reward_rate}}X</span>
            </div>

            <sliderdivVue :self_pledge="levelInfo.self_pledge"></sliderdivVue>
          </div>
        </div>
      </div>
      <!-- <van-swipe @change="changeswipe" class="my-swipe" :show-indicators="false" indicator-color="white" :loop="false">
        <van-swipe-item v-for="(item, index) in testlist" :key="index"> -->

      <!-- </van-swipe-item>
      </van-swipe> -->

      <p class="s_title">{{ $t('lang.d62') }}</p>
      <div class="yaoq">
        <div class="y_title">{{ $t('lang.d63') }}</div>
        <div class="y_line1" v-if="recommender != ''">
          <span v-if="invite_code == ''">----</span><span v-else>{{ linkshow }}</span>
        </div>
        <div class="y_line1" v-else>{{ $t('lang.d96') }}</div>
        <div class="y_button copys" @click="copy" :data-clipboard-text="`${invite_linkleft}/#/?invite_code=${account}`" v-if="parent_id > 0">{{ $t('lang.d64') }}</div>
        <div class="y_button" style="opacity: 0.4" v-else>{{ $t('lang.d64') }}</div>
       
      </div>

      <div class="jilu">
        <p class="j_title">{{ $t('lang.d65') }}</p>
        <div class="j_text" @click="popupfunopen" v-if="recommender == ''">{{ $t('lang.d97') }}</div>
        <div class="j_text" v-else>{{ recommender }}</div>

        <div class="j_sosuo">
          <span class="s_span"> {{ $t('lang.d66') }}</span>
          <!-- <div class="vant">
            <van-icon name="search" class="v_icon" />
         
            <van-field type="text" v-model="souoInput" input-align="left" @input="sosuofun" :placeholder="`${$t('lang.swap362')}`" class="van-field2" />
          </div> -->
        </div>
        <div class="s_boxs">
          <div class="b_title">
            <span>{{ $t('lang.d67') }}</span>
            <span style="margin-left: 50px">{{ $t('lang.d68') }}</span>
            <span>{{ $t('lang.d107') }}</span>
            <span>{{ $t('lang.d108') }}</span>
          </div>

          <div class="c_wlist" v-if="chelist.length == 0">
            <img src="@/assets/imgsnowball/wujilu.png" alt="" />
            <p>{{ $t('lang.d57') }}</p>
          </div>
          <van-list style="flex-direction: column;"  v-else v-model="loading" :finished="finished" :finished-text="`${$t('lang.d145')}`" @load="onLoad" >
            <div v-for="(item, index) in chelist" :key="index">
            <span>{{ item.address || '****' }}</span>
            <span style="color: #000; font-weight: bold">{{ $t(Fmgval(item.team_level))  }}</span>

            <span>{{ item.self_pledge }}</span>
            <span style="color: #000">{{ item.team_pledge }}</span>
          </div>
          </van-list>
         
        </div>
      </div>
      <div class="popup" v-show="popupshow">
        <div class="p_main">
          <p class="m_title">{{ $t('lang.d76') }}</p>
          <span>{{ $t('lang.d238') }}:</span>
          <van-field type="text" v-model="fromInput" input-align="left" placeholder="" class="van-field" />
          <div class="m_button" @click="postucbin">{{ $t('lang.d78') }}</div>
          <img src="@/assets/img/popupx.png" alt="" class="popupx" @click="popupfunclose" />
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import Clipboard from 'clipboard';
import { mapState } from 'vuex';
import { user, homedata, zhubi } from '@/api/index';
import topbutton from '@/components/topbutton.vue';

import sliderdivVue from './sliderdiv.vue';
export default {
  data() {
    return {
      levelInfo: {
        team_level:0
      },
      imgurl: '',
      popupshow: false,
      gerenmsg: {
        invite_code:''
      },
      chelist: [],
      inviteinfo: {},
      invite_code: '',
      invite_linkleft: '',
      fromInput: '',
      souoInput: '',
      tuanduilist: [],
      parent_id: 0,
      marketinfo: {},
      recommender: '',
      page:1,
      loading:false,
      finished:false,

      testlist: [
        {
          bgcard: require('@/assets/imgsnowball/card1.png'),
          color: '#575757',
          level: 'lang.d216',
        },
        {
          bgcard: require('@/assets/imgsnowball/card2.png'),
          color: '#7E1EC8',
          level: 'lang.d217',
        },
        {
          bgcard: require('@/assets/imgsnowball/card3.png'),
          color: '#5AADA5',
          level: 'lang.d218',
        },
        {
          bgcard: require('@/assets/imgsnowball/card4.png'),
          color: '#F2AB67',
          level: 'lang.d219',
        },
      ],
    };
  },

  created() {
    this.init();
  },
  mounted() {},
  watch: {
    account() {
      //获取账号
      this.init();
    },
    lang() {
      this.init();
    },
  },
  computed: {
    ...mapState(['account', 'lang']),
    linkshow() {
      var link = `${this.invite_linkleft}/#/?invite_code=${this.account}`;
      const head = link.slice(0, 22);
      const tail = link.slice(link.length - 5);
      return head + '****' + tail;
    },
    levelInfoComRate() {
      let pledge = this.levelInfo.team_pledge + this.levelInfo.mz_pledge;

      return (this.levelInfo.team_pledge / pledge) * 100;
    },
  },

  methods: {
    Fmgval(index) {
      return ['lang.swap79', 'lang.d217', 'lang.d218', 'lang.d219'][index];
    },
    onLoad() {
      this.page++;
      this.getucchildrenlistFun()
    },
    changeswipe(index) {
      console.log(index);
    },

    popupfunopen() {
      this.popupshow = true;
    },
    popupfunclose() {
      this.popupshow = false;
    },
    init() {
      this.finished =false
      this.chelist = []
      this.getinfo();
      this.getpledgelevelinfo();
      this.getucrecommender();
      this.getimgurl();
      this.getucchildrenlistFun();
    },
    sosuofun() {
      let data = {
        page_size: 10,
        address: this.souoInput,
      };
      user.getucchildrenlist(data,this.account).then(res => {
        console.log(res);
        this.chelist = res.data.list;
      });
    },
    getucchildrenlistFun() {
      let data ={
        page:this.page,
        page_size:10
      }
      user.getucchildrenlist(data,this.account).then(res => {
        if (res.code == 200) {
          if(res.data ==null){
            this.finished = true;
            return;
          }
          this.chelist = this.chelist.concat(res.data);
          if (res.data.length < 10) {
            this.finished = true;
          }
        }
      });
    },
    copy() {
     
      this.invite_link = this.account;
      if (this.invite_link) {
        var clipboard = new Clipboard('.copys');
        clipboard.on('success', e => {
          this.$toast(this.$t('lang.swap300'));
          clipboard.destroy(); // 释放内存
        });
        clipboard.on('error', e => {
          this.$toast(this.$t('lang.swap301'));
          clipboard.destroy(); // 释放内存
        });
      }
    },

    getpledgelevelinfo() {
      zhubi.getpledgelevelinfo(this.account).then(res => {
        console.log(res);
        this.levelInfo = res.data.level_info;
        this.$store.commit('SETLEVELINFO', this.levelInfo);
      });
    },
    getucrecommender() {
      zhubi.getucrecommender(this.account).then(res => {
        this.recommender = res.data.recommender;
      });
    },
    getinfo() {
      user.getucinfo(this.account).then(res => {
        console.log('w', res);
        this.gerenmsg = res.data;
        this.invite_code = this.gerenmsg.invite_code;
        this.parent_id = this.gerenmsg.parent_id;
      });
    },
    getimgurl() {
      homedata.gettokenimgurl().then(res => {
        this.imgurl = res.data.image_domain;
        this.invite_linkleft = res.data.domain;
      });
    },
    getinviteinfo() {
      user.getucinviteinfo(this.account).then(res => {
        console.log(res);
        this.inviteinfo = res.data;
      });
    },
    postucbin() {
      this.popupshow = false;
      let data = {
        parent_addr: this.fromInput,
      };
      user.postucbind(data, this.account).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.init();
        }
      });
    },
  },
  components: {
    topbutton,
    sliderdivVue,
  },
};
</script>
  
<style lang="less" scoped>
.sipper2 {
  margin-top: 160px;
  width: 100%;
  .sipper-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;
    font-size: 12px;
    color: #3d3d3d;
    font-weight: 500;
  }
  .sipper-button {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 2px solid #ae47fd;
    background: #fff;
    position: relative;
  }
  .sipper-button-abs {
    position: absolute;
    width: 50px;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    color: #477bfd;
  }
}
.sipper {
  margin-top: 20px;
  width: 100%;
  height: 201px;
  border-radius: 16px;
  opacity: 1;

  box-sizing: border-box;
  border: 1px solid #dedede;
  padding: 18px 20px;
  .sipper-button {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 2px solid #ae47fd;
  }
  .sipper-line {
    margin-bottom: 13px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .line-left {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0px;

      font-feature-settings: 'kern' on;
      color: #3d3d3d;
    }
    .line-right {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      text-align: right;
      letter-spacing: 0px;

      font-feature-settings: 'kern' on;
      color: #477bfd;
    }
  }
}
.my-swipe {
  width: 100%;
  .my-swipe-item {
    display: flex;
    flex-direction: column;
    padding: 21px;
    position: relative;
    width: 100%;
    height: 313px;
    background: url(../../../assets/imgsnowball/card1.png) no-repeat;
    background-size: contain !important;
  }
  .item-title {
    position: absolute;
    left: 19px;
    top: 21px;
    font-size: 18px;
    font-weight: bold;
  }
  .item-rate {
    position: absolute;
    left: 38px;
    top: 66px;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33px;
    height: 16px;
    border-radius: 58px;
    opacity: 1;

    background: #d8d8d8;
  }
  .item-text {
    width: 110px;
    position: absolute;
    left: 19px;
    top: 124px;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
  }
  .item-level {
    width: 102px;
    text-align: center;
    position: absolute;
    right: 25px;
    top: 128px;
    font-family: YouSheBiaoTiHei;
    font-size: 18px;
    font-weight: bold;
    line-height: 15px;
    letter-spacing: 0px;

    color: #477bfd;
  }
}
.shequ {
  width: 375px;
  border-radius: 20px 20px 0 0;
  padding: 20px;

  background: #fff;

  .s_title {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Poppins';
  }

  .vvs {
    width: 100%;
    overflow-x: scroll;
    display: flex;

    .vv {
      flex-shrink: 0;
      width: 100%;
      height: 200px;

      border-radius: 14px;
      background-size: cover !important;
      margin-right: 30px;
      position: relative;

      .biao {
        width: 55px;
        height: 25px;
        border-radius: 46px 46px 56px 46px;
        color: #000;
        font-size: 10px;
        font-weight: 700;
        font-family: 'PingFang';
        text-align: center;
        line-height: 25px;
        position: absolute;

        top: 25px;
        left: 30px;
      }

      .text {
        position: absolute;
        bottom: 20px;
        left: 30px;
        width: 100%;
        font-size: 14px;
        font-weight: 700;
        font-family: 'PingFang';
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 70px;
      }
    }
  }
}

.yaoq {
  width: 100%;
  margin: 0 auto;

  padding: 20px;
  border-radius: 20px;
  opacity: 1;
  background: #f7f7f7;
  box-shadow: 0 0 12px 0 #f7f7f7;

  .y_line1 {
    width: 100%;
    height: 52px;
    border-radius: 10px;
    text-align: center;
    line-height: 52px;
    background: #ffffff;
    font-size: 12px;
    color: #000;
  }

  .y_title {
    opacity: 1;
    color: #000;
    font-size: 14px;
    font-weight: 700;
    font-family: 'PingFang';
    margin-bottom: 20px;
  }

  .y_button {
    margin-top: 30px;
    width: 100%;
    height: 52px;
    border-radius: 62px;
    background: #4583ff;

    text-align: center;
    line-height: 52px;
    color: #ffffffff;
    font-size: 14px;
    font-weight: 700;
    font-family: 'PingFang';
  }
}

.jilu {
  width: 100%;

  margin-top: 20px;

  .j_title {
    text-align: left;
    color: #000;
    font-size: 18px;
    font-weight: 700;
    font-family: 'PingFang';
  }

  .j_text {
    width: 100%;
    height: 59px;
    border-radius: 20px;
    background: #f7f7f7;
    box-shadow: 0 0 12px 0 #f7f7f7;
    color: #000;
    font-size: 14px;
    font-weight: 700;
    font-family: 'PingFang';
    text-align: center;
    line-height: 59px;
  }

  .j_sosuo {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

    .s_span {
      color: #000;
      font-size: 18px;
      font-weight: 700;
      font-family: 'PingFang';
    }
  }
}

.van-field {
  width: 228px;
  margin: 0 auto;

  height: 48px;
  border-radius: 4px;
  font-weight: bold;
  background: #f7f7f7 !important;
  padding: 14px !important;

  /deep/ .van-field__control {
    font-size: 14px;
    color: #000;
    background: #f7f7f7 !important;
  }
}

.vant {
  width: 202px;
  height: 34px;
  position: relative;
  background: #f7f7f7;
  border-radius: 20px;

  .v_icon {
    margin-top: 12px;
    margin-left: 7px;
  }

  .van_sosuo {
    float: right;
    padding: 0;
    color: #fff;
    width: 170px;
    height: 34px;

    border: none;
    background: none;
    box-shadow: 0 0 12px 0 #080a1b1a;
  }
}

.y_cont {
  width: 100%;
  margin-top: 30px;

  .c_li {
    color: #999999ff;
    font-size: 14px;
    font-weight: 700;
    font-family: 'PingFang';
    padding: 10px;
  }
}

.popup {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
  transition: transform 0.5s ease;

  .p_main {
    width: 288px;
    height: 248px;
    border-radius: 10px;
    padding: 10px;
    background: #ffffffff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -70%);

    .m_button {
      width: 228px;
      height: 48px;
      border-radius: 65px;
      margin: 0 auto;
      margin-top: 20px;
      color: #ffffffff;
      font-size: 14px;
      font-weight: 700;
      font-family: 'PingFang';
      text-align: center;
      line-height: 48px;
      background: #5c90fe;
    }

    .m_title {
      color: #2f272aff;
      font-size: 20px;
      font-weight: 700;
      font-family: 'PingFang';
      text-align: center;
    }

    span {
      color: #000000ff;
      font-size: 14px;
      font-weight: 700;
      font-family: 'PingFang';
      float: left;
      margin-bottom: 20px;
    }
  }
}

.popupx {
  width: 36px;
  height: 36px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -100px;
}

.s_boxs {
  width: 100%;
  margin-top: 20px;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 16px;

  background: #f7f7f7;

  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .b_title {
    color: #999999ff;
    font-size: 14px;
    font-weight: 500;
    font-family: 'PingFang';
  }

  .b_cont {
    color: #999999ff;
    font-size: 14px;
    font-weight: 700;
    font-family: 'PingFang';
  }
}

.c_wlist {
  width: 100%;
  height: 219px;
  border-radius: 20px;
  opacity: 1;
  background: #f7f7f7;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 122px;
    height: 92px;
  }

  p {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    font-family: 'PingFang';
  }
}

.van-field2 {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 !important;
  padding-top: 5px !important;
  color: #fff;
  width: 170px;
  height: 34px;

  border: none;
  background: none !important;
  box-shadow: 0 0 12px 0 #080a1b1a;

  /deep/ .van-field__control {
    font-size: 14px;
    color: #fff;
  }
}

/deep/.van-progress {
  width: 278px;
  height: 6px;
}

.rate {
  width: 55px;
  height: 25px;
  border-radius: 46px 46px 56px 46px;
  color: #ffffffff;
  font-size: 10px;
  font-weight: 700;
  font-family: 'PingFang';
  text-align: center;
  line-height: 25px;
  position: absolute;

  top: 65px;
  left: 110px;
}

.shichang {
  width: 100%;
  height: 155px;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 21px 16px;
  background: #f7f7f7;
  box-shadow: 0 0 12px 0 #f7f7f7;

  .line {
    height: 49%;
    width: 100%;

    div {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

  .lin {
    width: 100%;
    height: 1px;
    background: #3b3c40;
    margin-bottom: 10px;
  }
}
</style>